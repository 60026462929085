import React from "react";
import { Box, Typography, Container, Link } from "@mui/material";

const TermsAndConditions: React.FC = () => {
  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          p: 4,
          borderRadius: 4,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Terms and Conditions
        </Typography>

        <Typography variant="h6" gutterBottom>
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to Tranzcribe! These Terms and Conditions govern your use of
          the Tranzcribe website and services. By accessing and using our
          services, you agree to be bound by these Terms. If you do not agree
          with these Terms, please do not use our services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          2. Definitions
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>"Tranzcribe"</strong> refers to the owner and operator of the
          service. <br />
          <strong>"Service"</strong> refers to the transcription services
          provided by Tranzcribe. <br />
          <strong>"You"</strong> refers to the user or customer accessing the
          Tranzcribe services.
        </Typography>

        <Typography variant="h6" gutterBottom>
          3. User Accounts
        </Typography>
        <Typography variant="body1" paragraph>
          To access certain features of our services, you must create an
          account. You are responsible for maintaining the confidentiality of
          your account and password and for restricting access to your account.
          You agree to accept responsibility for all activities that occur under
          your account or password.
        </Typography>

        <Typography variant="h6" gutterBottom>
          4. Payment and Billing
        </Typography>
        <Typography variant="body1" paragraph>
          By purchasing a subscription or making a payment on Tranzcribe, you
          agree to provide accurate and current payment information. All fees
          are due at the time of purchase. Tranzcribe reserves the right to
          change pricing at any time, with notice provided to users.
        </Typography>

        <Typography variant="h6" gutterBottom>
          5. Cancellation and Refunds
        </Typography>
        <Typography variant="body1" paragraph>
          You may cancel your subscription at any time. Cancellations will take
          effect at the end of your current billing period, and no refunds will
          be issued for the remaining duration of the subscription.
        </Typography>

        <Typography variant="h6" gutterBottom>
          6. Prohibited Uses
        </Typography>
        <Typography variant="body1" paragraph>
          You may not use Tranzcribe’s services for any illegal or unauthorized
          purpose. You agree to comply with all laws, rules, and regulations
          applicable to your use of the services. Prohibited activities include,
          but are not limited to:
        </Typography>
        <ul>
          <li>
            Transmitting any harmful or disruptive code, viruses, or malware.
          </li>
          <li>Interfering with the operation of the service.</li>
          <li>Using the service for any fraudulent or deceptive purpose.</li>
        </ul>

        <Typography variant="h6" gutterBottom>
          7. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          All content provided on Tranzcribe, including text, images, graphics,
          and code, is the intellectual property of Tranzcribe or its licensors.
          You agree not to reproduce, distribute, or create derivative works
          from this content without express permission from Tranzcribe.
        </Typography>

        <Typography variant="h6" gutterBottom>
          8. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          To the fullest extent permitted by law, Tranzcribe and its affiliates
          will not be liable for any indirect, incidental, special,
          consequential, or punitive damages, or any loss of profits or
          revenues, whether incurred directly or indirectly, or any loss of
          data, use, goodwill, or other intangible losses, resulting from:
          <ul>
            <li>Your use of or inability to access the services.</li>
            <li>Any unauthorized access to or use of our servers.</li>
            <li>
              Any bugs, viruses, or other harmful code that may be transmitted
              to or through our services.
            </li>
          </ul>
        </Typography>

        <Typography variant="h6" gutterBottom>
          9. Termination
        </Typography>
        <Typography variant="body1" paragraph>
          Tranzcribe reserves the right to terminate or suspend your account at
          our discretion, with or without notice, for any violation of these
          Terms and Conditions.
        </Typography>

        <Typography variant="h6" gutterBottom>
          10. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms and Conditions are governed by and construed in accordance
          with the laws of [Your Jurisdiction], without regard to its conflict
          of law provisions. Any disputes arising from these Terms will be
          subject to the exclusive jurisdiction of the courts located in [Your
          Jurisdiction].
        </Typography>

        <Typography variant="h6" gutterBottom>
          11. Changes to These Terms
        </Typography>
        <Typography variant="body1" paragraph>
          Tranzcribe reserves the right to modify these Terms and Conditions at
          any time. We will notify you of any significant changes to the terms
          by posting the updated version on our website. Your continued use of
          the services after any changes are made will constitute your
          acceptance of the new Terms.
        </Typography>

        <Typography variant="h6" gutterBottom>
          12. Contact Us
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns regarding these Terms and
          Conditions, please contact us at{" "}
          <Link href="mailto:support@tranzcribe.com">
            support@tranzcribe.com
          </Link>
          .
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
