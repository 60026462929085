import React, { useEffect, useState } from "react";
import { Box, Button, ButtonGroup } from "@mui/material";
import ReactMarkdown from "react-markdown";
import {
  EmailRefinementTypes,
  EmailTypes,
  useGetEmailPromptMutation,
  useGetEmailRefinementPromptMutation,
} from "../store/apiSlice";
import {showErrorToast, showSuccessToast} from "../toastUtils";
import { getErrorMessage } from "../error-helpers/errorMessage";
import LoadingPage from "../common/LoadingPage";
import { logAnalyticsEvent } from "../config/firebase";
import { logPosthogEvent } from "../common/posthogUtils";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface PrepareEmailTabProps {
  inputText: string;
  handleDownload: (text: string | null, filename: string) => void;
}

const PrepareEmailTab: React.FC<PrepareEmailTabProps> = ({
  inputText,
  handleDownload,
}) => {
  const [text, setText] = useState<string>(inputText);
  const [refinedText, setRefinedText] = useState<null | string>(null);
  const [selectedPrimaryButton, setSelectedPrimaryButton] =
    useState<EmailTypes>(EmailTypes.PROFESSIONAL);
  const [selectedSecondaryButton, setSelectedSecondaryButton] =
    useState<EmailRefinementTypes | null>(null);

  const [getEmailPrompt, { isLoading: emailPromptLoading }] =
    useGetEmailPromptMutation();
  const [getEmailRefinement, { isLoading: emailRefinementLoading }] =
    useGetEmailRefinementPromptMutation();
  const user = useSelector((state: RootState) => state.user);

  const isLoading = emailPromptLoading || emailRefinementLoading;

  const getGptResponse = async (selectedType: EmailTypes) => {
    try {
      logAnalyticsEvent("getEmailPrompt", { selectedType });
      logPosthogEvent("getEmailPrompt", { selectedType }, user.user?.email);
      const response = await getEmailPrompt({
        text: inputText,
        type: selectedType,
      }).unwrap();
      setRefinedText(null);
      setText(response.gptTextResponse);
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  const getGptEmailRefinementResponse = async (
    refinement: EmailRefinementTypes,
  ) => {
    try {
      logAnalyticsEvent("getEmailRefinement", { refinement });
      logPosthogEvent("getEmailRefinement", { refinement }, user.user?.email);
      const response = await getEmailRefinement({
        text,
        refinement,
      }).unwrap();
      setRefinedText(response.gptTextResponse);
    } catch (e) {
      showErrorToast(getErrorMessage(e));
    }
  };

  const handlePrimaryButtonClick = (newType: EmailTypes) => {
    if (isLoading) {
      return;
    }

    setSelectedPrimaryButton(newType);
    setSelectedSecondaryButton(null);
    getGptResponse(newType);
  };

  const handleSecondaryButtonClick = (refinement: EmailRefinementTypes) => {
    if (isLoading) {
      return;
    }

    setSelectedSecondaryButton(refinement);
    getGptEmailRefinementResponse(refinement);
  };

  useEffect(() => {
    getGptResponse(EmailTypes.PROFESSIONAL);
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "16px",
        backgroundColor: "#1B1E1E",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          maxWidth: "600px",
          width: "100%",
          padding: "16px",
        }}
      >
        <ButtonGroup
          disabled={isLoading}
          variant="outlined"
          aria-label="primary button group"
          sx={{
            mb: 2,
            flexWrap: "wrap",
            "& .MuiButton-root": {
              borderColor: "#c49b40",
              color: "#ffffff",
              borderRadius: "24px",
              padding: "6px 16px",
              textTransform: "none",
              transition: "background-color 0.3s, border-color 0.3s",
              margin: "4px",
              "&:hover": {
                borderColor: "#c49b40",
                backgroundColor: "#c49c50",
                color: "#000000",
              },
              "&.Mui-disabled": {
                color: "#c49b40",
                opacity: 0.6,
              },
            },
            "@media (max-width: 600px)": {
              "& .MuiButton-root": {
                flex: "1 1 100%",
              },
            },
          }}
        >
          <Button
            onClick={() => handlePrimaryButtonClick(EmailTypes.PROFESSIONAL)}
            sx={{
              backgroundColor:
                selectedPrimaryButton === EmailTypes.PROFESSIONAL
                  ? "#c49b40"
                  : "transparent",
              color:
                selectedPrimaryButton === EmailTypes.PROFESSIONAL
                  ? "#FFFFFF"
                  : "#c49b40",
            }}
          >
            Professional
          </Button>
          <Button
            onClick={() => handlePrimaryButtonClick(EmailTypes.FRIENDLY)}
            sx={{
              backgroundColor:
                selectedPrimaryButton === EmailTypes.FRIENDLY
                  ? "#c49b40"
                  : "transparent",
              color:
                selectedPrimaryButton === EmailTypes.FRIENDLY
                  ? "#FFFFFF"
                  : "#c49b40",
            }}
          >
            Friendly
          </Button>
          <Button
            onClick={() => handlePrimaryButtonClick(EmailTypes.CONCISE)}
            sx={{
              backgroundColor:
                selectedPrimaryButton === EmailTypes.CONCISE
                  ? "#c49b40"
                  : "transparent",
              color:
                selectedPrimaryButton === EmailTypes.CONCISE
                  ? "#FFFFFF"
                  : "#c49b40",
            }}
          >
            Concise
          </Button>
        </ButtonGroup>

        {selectedPrimaryButton && (
          <ButtonGroup
            disabled={isLoading}
            variant="outlined"
            aria-label="secondary button group"
            sx={{
              flexWrap: "wrap",
              "& .MuiButton-root": {
                borderColor: "#c49b40",
                color: "#ffffff",
                borderRadius: "24px",
                padding: "6px 16px",
                textTransform: "none",
                transition: "background-color 0.3s, border-color 0.3s",
                margin: "4px",
                "&:hover": {
                  borderColor: "#c49b40",
                  backgroundColor: "#c49c50",
                  color: "#000000",
                },
                "&.Mui-disabled": {
                  color: "#c49b40",
                  opacity: 0.6,
                },
              },
              "@media (max-width: 600px)": {
                "& .MuiButton-root": {
                  flex: "1 1 100%",
                },
              },
            }}
          >
            <Button
              onClick={() =>
                handleSecondaryButtonClick(EmailRefinementTypes.REWRITE)
              }
              sx={{
                backgroundColor:
                  selectedSecondaryButton === EmailRefinementTypes.REWRITE
                    ? "#c49b40"
                    : "transparent",
                color:
                  selectedSecondaryButton === EmailRefinementTypes.REWRITE
                    ? "#FFFFFF"
                    : "#c49b40",
              }}
            >
              Rewrite
            </Button>
          </ButtonGroup>
        )}
      </Box>

      <Box
        sx={{
          textAlign: "left",
          width: "100%",
          maxWidth: "600px",
          padding: "16px",
          color: "#ffffff",
        }}
      >
        {isLoading ? (
          <LoadingPage />
        ) : (
          <ReactMarkdown>{refinedText ?? text}</ReactMarkdown>
        )}

        <Button
          variant="contained"
          onClick={() => handleDownload(text, "email-tailored.pdf")}
          sx={{
            mt: 4,
            backgroundColor: "#c49b40",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#b28434",
            },
          }}
        >
          Download
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            const contentToCopy = refinedText ?? text;
            if (contentToCopy) {
              navigator.clipboard
                .writeText(contentToCopy)
                .then(() => {
                  showSuccessToast("Copied to clipboard!");
                })
                .catch((err) => {
                  showErrorToast(
                    `Failed to copy to clipboard ${getErrorMessage(err)}`,
                  );
                });
            }
          }}
          sx={{
            mt: 4,
            backgroundColor: "#c49b40",
            color: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#b28434",
            },
          }}
          startIcon={<ContentCopyIcon />}
        >
          Copy to Clipboard
        </Button>
      </Box>
    </Box>
  );
};

export default PrepareEmailTab;
