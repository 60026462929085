import React, { useState, useRef, useEffect, useCallback } from "react";
import { useProcessAudioMutation } from "../store/apiSlice";
import { showErrorToast, showWarningToast } from "../toastUtils";
import {
  Box,
  Typography,
  IconButton,
  Tabs,
  Tab,
  Button,
  ThemeProvider,
} from "@mui/material";
import StopIcon from "@mui/icons-material/Stop";
import MicIcon from "@mui/icons-material/Mic";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import LoadingPage from "../common/LoadingPage";
import { getErrorMessage } from "../error-helpers/errorMessage";
import JsPDF from "jspdf";
import { getWhiteTextLogo } from "../common/commonWidgets";
import { formatElapsedTime } from "../common/commonFuncs";
import logo from "../images/tranzcribe-logo-text-black.png";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import {
  countdownBoxStyles,
  countdownTypographyStyles,
  darkMicIconStyles,
  darkStopMicIconStyles,
  tabStyles,
} from "./styles";
import { logAnalyticsEvent } from "../config/firebase";
import SummarizationTab from "./SummarizationTab";
import PrepareEmailTab from "./PrepareEmailTab";
import WriteDocumentTab from "./WriteDocumentTab";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Summarize } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { logPosthogEvent } from "../common/posthogUtils";
import { darkTheme } from "../theme";
import AudioWaveformV1 from "./AudioWaveformV1";

const AudioRecorder: React.FC = () => {
  const [isRecording, setIsRecording] = useState(false);
  const [hasRecorded, setHasRecorded] = useState(false);
  const [, setAudioUrl] = useState<string | null>(null);
  const [transcribedText, setTranscribedText] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [countdown, setCountdown] = useState<number | null>(null);
  const [elapsedTime, setElapsedTime] = useState<number>(0);
  const [language] = useState<string>("en");
  const [showCompletedIcon, setShowCompletedIcon] = useState(false);
  const elapsedTimeRef = useRef(0);

  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const audioBlobRef = useRef<Blob | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const recordingTimerRef = useRef<NodeJS.Timeout | null>(null);

  const [processAudio, { isLoading }] = useProcessAudioMutation();

  const user = useSelector((state: RootState) => state.user);

  const handleProcessAudio = useCallback(async () => {
    if (!audioBlobRef.current) {
      showWarningToast("No audio recorded. Please record audio first.");
      return;
    }
    const formData = new FormData();
    formData.append("audio", audioBlobRef.current, "recording");
    formData.append("language", language);
    formData.append("videoLength", elapsedTimeRef.current.toString());
    try {
      const response = await processAudio(formData).unwrap();
      setTranscribedText(response.transcribedText);
    } catch (error) {
      showErrorToast(`Failed to process audio: ${getErrorMessage(error)}`);
    }
  }, [processAudio, language]);

  const startRecording = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStreamRef.current = stream;

      let mimeType: string = "";
      if (MediaRecorder.isTypeSupported("audio/webm")) {
        mimeType = "audio/webm";
      } else if (MediaRecorder.isTypeSupported("audio/mp4")) {
        mimeType = "audio/mp4";
      } else if (MediaRecorder.isTypeSupported("audio/mpeg")) {
        mimeType = "audio/mpeg";
      } else {
        showErrorToast("No supported audio format found for recording.");
        return;
      }
      logAnalyticsEvent("startRecording", {
        mimeType,
      });
      logPosthogEvent(
        "startRecording",
        {
          mimeType,
        },
        user.user?.email,
      );

      const mediaRecorder = new MediaRecorder(stream, { mimeType });
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: mimeType });
        audioBlobRef.current = audioBlob;

        const audioUrl = URL.createObjectURL(audioBlob);
        setAudioUrl(audioUrl);
        audioChunksRef.current = [];

        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach((track) => track.stop());
          mediaStreamRef.current = null;
        }

        logAnalyticsEvent("stoppedRecording", {
          mimeType,
        });
        logPosthogEvent(
          "stoppedRecording",
          {
            mimeType,
          },
          user.user?.email,
        );

        clearRecordingTimer();
        handleProcessAudio();
        setShowCompletedIcon(true);
        setTimeout(() => setShowCompletedIcon(false), 2000);
        setHasRecorded(true);
      };

      mediaRecorder.start();
      setIsRecording(true);
      setElapsedTime(0);
      elapsedTimeRef.current = 0;
      startRecordingTimer();
    } catch (error) {
      showErrorToast(
        `Could not access the microphone. Please check your permissions (${getErrorMessage(error)}).`,
      );
    }
  }, [handleProcessAudio]);

  const startCountdown = () => {
    setCountdown(3);
  };

  useEffect(() => {
    if (countdown === null) return;

    if (countdown > 0) {
      const timer = setTimeout(
        () => setCountdown((prev) => (prev ?? 0) - 1),
        1000,
      );
      return () => clearTimeout(timer);
    }

    if (countdown === 0) {
      startRecording();
      setCountdown(null);
    }
  }, [countdown, startRecording]);

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop();
      clearRecordingTimer();
      setIsRecording(false);
    }
  };

  const startRecordingTimer = () => {
    recordingTimerRef.current = setInterval(() => {
      elapsedTimeRef.current += 1;
      setElapsedTime(elapsedTimeRef.current); // This updates UI without affecting `elapsedTimeRef`
    }, 1000);
  };

  const clearRecordingTimer = () => {
    if (recordingTimerRef.current) {
      clearInterval(recordingTimerRef.current);
      recordingTimerRef.current = null;
    }
  };

  const handleDownload = async (text: string | null, filename: string) => {
    if (!text) return;

    const doc = new JsPDF();
    doc.setFontSize(12);

    // Add the logo at the top (centered)
    const imgWidth = 75; // Adjust width as needed
    const imgHeight = 35; // Adjust height as needed
    const pageWidth = doc.internal.pageSize.getWidth();
    const marginX = (pageWidth - imgWidth) / 2; // Center the image horizontally

    doc.addImage(logo, "PNG", marginX, 10, imgWidth, imgHeight);

    // Add the text below the logo
    const lines = doc.splitTextToSize(text, 180);
    doc.text(lines, 10, imgHeight + 20);

    // Add black outline around the PDF page
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setLineWidth(0.2);
    doc.setDrawColor(0, 0, 0); // Black color for the outline
    doc.rect(5, 5, pageWidth - 10, pageHeight - 10); // Rectangle outline

    logAnalyticsEvent("handleDownload", {
      filename,
    });
    doc.save(filename.endsWith(".pdf") ? filename : `${filename}.pdf`);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    logAnalyticsEvent("handlePromptChange", {
      activeTab: newValue,
    });
    setActiveTab(newValue);
  };

  const handleMicClick = () => {
    if (hasRecorded) {
      setHasRecorded(false);
      setTranscribedText(null);
      setActiveTab(0);
      startCountdown();
    } else {
      startCountdown();
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box
        height={"95vh"}
        sx={{
          textAlign: "center",
          color: "#333333",
          backgroundColor: darkTheme.palette.background.default,
        }}
      >
        {/* Updated this Box to adjust the layout */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection={isRecording ? "row" : "column"}
          onClick={handleMicClick}
          sx={{ cursor: "pointer" }}
          width="100%"
        >
          {getWhiteTextLogo()}
        </Box>

        {/* The rest of your code remains mostly the same, with minor adjustments */}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={handleMicClick}
          sx={{ textAlign: "center", mb: 4, cursor: "pointer", mt: 4 }}
        >
          {hasRecorded && (
            <Button color="primary" onClick={handleMicClick} sx={tabStyles}>
              <Box color={"#ffffff"}>
                <MicIcon />
                <Typography>Record a new message</Typography>
              </Box>
            </Button>
          )}
        </Box>

        {!hasRecorded && countdown === null && (
          <Box mt={20} mb={20}>
            <IconButton
              color={isRecording ? "secondary" : "primary"}
              onClick={isRecording ? stopRecording : startCountdown}
              sx={{
                mb: 2,
                color: "#FFFFFF",
                transform: "scale(5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "0 auto",
                width: "fit-content",
                animation: isRecording ? "pulsate 2s infinite" : "none",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                boxShadow: "0px 0px 5px rgba(255, 255, 255, 0.5)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
                "@keyframes pulsate": {
                  "0%": {
                    transform: "scale(5)",
                    color: "#FFFFFF",
                  },
                  "50%": {
                    transform: "scale(5.1)",
                    color: "red",
                  },
                  "100%": {
                    transform: "scale(5)",
                    color: "#FFFFFF",
                  },
                },
              }}
            >
              {isRecording ? (
                <StopIcon fontSize="large" />
              ) : (
                <MicIcon
                  fontSize="large"
                  sx={{
                    color: "#FFFFFF",
                    padding: "4px",
                    borderRadius: "50%",
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                    boxShadow: "0px 0px 5px rgba(255, 255, 255, 0.5)",
                  }}
                />
              )}
            </IconButton>
          </Box>
        )}

        {countdown !== null ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            sx={countdownBoxStyles}
            onClick={() => {
              setCountdown(null);
            }}
          >
            <Typography variant="h1" sx={countdownTypographyStyles}>
              {countdown}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#fff",
                fontWeight: "medium",
                mt: 1,
              }}
            >
              Stop
            </Typography>
          </Box>
        ) : isRecording ? (
          <Typography
            variant="h6"
            sx={{ mt: 1, color: "red", fontSize: "32px" }}
          >
            {formatElapsedTime(elapsedTime)}
          </Typography>
        ) : showCompletedIcon ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ mt: 1 }}
          >
            <ThumbUpIcon color="success" sx={{ mr: 1 }} />
            <Typography variant="h6" sx={{ color: "green" }}>
              Completed!
            </Typography>
          </Box>
        ) : null}

        {isRecording && mediaStreamRef && mediaStreamRef.current && (
          <Box mt={10}>
            <AudioWaveformV1 mediaStream={mediaStreamRef.current} />
          </Box>
        )}

        {isLoading && <LoadingPage />}

        {transcribedText && (
          <Box>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              centered
              textColor="inherit"
              scrollButtons
              TabIndicatorProps={{ style: { display: "none" } }}
            >
              <Tab label="Summary" icon={<EditNoteIcon />} sx={tabStyles} />
              <Tab label="Write an email" icon={<Summarize />} sx={tabStyles} />
              <Tab
                label="Write a document"
                icon={<EditIcon />}
                sx={tabStyles}
              />
            </Tabs>

            {activeTab === 0 && transcribedText && (
              <SummarizationTab
                inputText={transcribedText}
                handleDownload={handleDownload}
              />
            )}
            {activeTab === 1 && transcribedText && (
              <PrepareEmailTab
                inputText={transcribedText}
                handleDownload={handleDownload}
              />
            )}
            {activeTab === 2 && transcribedText && (
              <WriteDocumentTab
                inputText={transcribedText}
                handleDownload={handleDownload}
              />
            )}
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default AudioRecorder;
