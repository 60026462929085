import React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import UpgradeIcon from "@mui/icons-material/Upgrade";
import { useNavigate } from "react-router-dom";
import { getBlackTextLogo } from "../../common/commonWidgets";
import { otterTheme } from "../../theme";

const StartForFree: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#f4f6f8",
        minHeight: "100vh",
        paddingTop: 6,
      }}
    >
      {getBlackTextLogo()}
      <Typography variant="h4" sx={{ marginBottom: 4, fontWeight: "bold" }}>
        Join our beta with Tranzcribe
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: 4,
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
        }}
      >
        {/*
        <Card
          sx={{
            width: 345,
            padding: 3,
            backgroundColor: "#ffffff",
            boxShadow: 3,
            borderRadius: "16px",
          }}
        >
          <CardContent sx={{ textAlign: "center" }}>
            <IconButton
              disableRipple
              sx={{
                backgroundColor: "#E3F2FD",
                color: "#1976d2",
                mb: 2,
                fontSize: "1.5rem",
              }}
            >
              <HelpOutlineIcon />
            </IconButton>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#1976d2" }}
            >
              Basic
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginBottom: 2 }}
            >
              The easiest way to try Tranzcribe
            </Typography>

            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              Free
            </Typography>

            <Button
              variant="outlined"
              color="primary"
              sx={{
                borderColor: "#1976d2",
                color: "#1976d2",
                marginTop: 2,
                marginBottom: 2,
                borderRadius: "24px",
                padding: "10px 16px",
              }}
              onClick={() => navigate("/sign-up?plan=FREE")}
            >
              Get Started
            </Button>

            <Divider sx={{ marginY: 2 }} />

            <Box sx={{ textAlign: "left" }}>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
              >
                <SummarizeIcon sx={{ marginRight: 1 }} /> AI meeting assistant
                transcribes and summarizes in real time
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
              >
                <EmailIcon sx={{ marginRight: 1 }} /> Email integration and
                summaries
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ display: "flex", alignItems: "center", marginBottom: 1 }}
              >
                <DescriptionIcon sx={{ marginRight: 1 }} /> Document editing
                tools
              </Typography>
            </Box>
          </CardContent>
        </Card> */}

        {/* Pro Plan Card */}
        <Card
          sx={{
            width: 345,
            padding: 3,
            backgroundColor: "#ffffff",
            boxShadow: 3,
            borderRadius: "16px",
          }}
        >
          <CardContent sx={{ textAlign: "center" }}>
            <IconButton
              disableRipple
              sx={{
                backgroundColor: "#ede7f6",
                mb: 2,
                fontSize: "1.5rem",
                color: otterTheme.palette.primary.main,
              }}
            >
              <UpgradeIcon />
            </IconButton>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                color: otterTheme.palette.primary.main,
              }}
            >
              Tranzcribe Open Beta
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ marginBottom: 2 }}
            >
              Unlock powerful tools to help you customize and enhance your
              transcriptions, emails, and documents.
            </Typography>

            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              FREE!
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              sx={{
                backgroundColor: "#1976d2",
                color: "#fff",
                marginTop: 2,
                marginBottom: 2,
                borderRadius: "24px",
                padding: "10px 16px",
              }}
              onClick={() => navigate("/sign-up?plan=PRO")}
            >
              Join the beta
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default StartForFree;
