import { Box } from "@mui/material";
import logo from "../images/tranzcribe-logo-text-black.png";
import logoOnly from "../images/tranzcribe-logo-only-white.png";
import logoWhite from "../images/tranzcribe-logo-text.png";

export const getBlackTextLogo = () => {
  return (
    <Box
      sx={{
        marginBottom: 0,
        width: "100%",
        maxWidth: 450, // Maximum width for larger screens
        height: "auto",
        "@media (max-width: 600px)": {
          maxWidth: 300, // Reduce the size on mobile screens
        },
        "@media (max-width: 400px)": {
          maxWidth: 200, // Further reduce the size on very small screens
        },
      }}
    >
      <img
        src={logo}
        alt="Logo"
        style={{ width: "100%", height: "auto" }} // Ensure the image scales properly with the box
      />
    </Box>
  );
};

export const getWhiteTextLogo = () => {
  return (
    <Box
      sx={{
        marginBottom: 0,
        width: "100%",
        maxWidth: 450, // Maximum width for larger screens
        height: "auto",
        "@media (max-width: 600px)": {
          maxWidth: 300, // Reduce the size on mobile screens
        },
        "@media (max-width: 400px)": {
          maxWidth: 200, // Further reduce the size on very small screens
        },
      }}
    >
      <img
        src={logoWhite}
        alt="logo-white.png"
        style={{ width: "100%", height: "auto" }} // Ensure the image scales properly with the box
      />
    </Box>
  );
};

export const getTranzcribeLogo = () => {
  return (
    <Box
      sx={{
        marginBottom: 0,
        width: "100%",
        maxWidth: 450, // Maximum width for larger screens
        height: "auto",
        "@media (max-width: 600px)": {
          maxWidth: 300, // Reduce the size on mobile screens
        },
        "@media (max-width: 400px)": {
          maxWidth: 200, // Further reduce the size on very small screens
        },
      }}
    >
      <img
        src={logoOnly}
        alt="Logo"
        style={{ width: "100%", height: "auto" }} // Ensure the image scales properly with the box
      />
    </Box>
  );
};
