import React from "react";
import { Box } from "@mui/material";
import AudioTranscriber from "./components/AudioTranscriber";
import Homepage from "./components/home/HomePage";
import { ToastContainer } from "react-toastify";
import { Routes, Route } from "react-router-dom";
import StartForFree from "./components/start-for-free/StartForFree";
import Login from "./components/login/Login";
import SignUpPage from "./components/sign-up/SignUpPage";
import PasswordReset from "./components/reset-password/PasswordReset";
import ProtectedRoute from "./components/protected-route/ProtectedRoute";
import NavBar from "./components/navbar/NavBar";
import Profile from "./components/profile/Profile";
import SignUpMonthlySubscription from "./components/sign-up/SignUpMonthlySubscription";
import TermsAndConditions from "./components/terms-and-conditions/TermsAndConditions";
import Settings from "./components/settings/Settings";
import SubscriptionPage from "./components/subscription-page/SubscriptionPage";
import BillingHistoryPage from "./billing-history/BillingHistoryPage";

function App() {
  return (
    <Box width={"100%"}>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/start" element={<StartForFree />} />
        <Route path="/sign-up" element={<SignUpPage />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/purchase" element={<SignUpMonthlySubscription />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route
          path={"/*"}
          element={
            <ProtectedRoute>
              <NavBar />
              <Routes>
                <Route path="/dashboard" element={<AudioTranscriber />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/settings" element={<Settings />} />
                <Route
                  path="/manage/subscription"
                  element={<SubscriptionPage />}
                />
                <Route
                  path="/view/billing-history"
                  element={<BillingHistoryPage />}
                />
              </Routes>
            </ProtectedRoute>
          }
        ></Route>
      </Routes>
    </Box>
  );
}

export default App;
