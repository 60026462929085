import { isMobile } from "react-device-detect";
import { darkTheme } from "../theme";

export const countdownTypographyStyles = {
  color: "#fff",
  fontWeight: "bold",
  animation: "pop 1s ease-out infinite", // Add the pop animation for each second
  "@keyframes pop": {
    "0%": {
      opacity: 1,
      transform: "scale(1)",
    },
    "50%": {
      opacity: 0,
      transform: "scale(1)",
    },
    "100%": {
      opacity: 0,
    },
  },
};

export const countdownBoxStyles = {
  width: 170,
  height: 170,
  borderRadius: "50%",
  backgroundColor: "rgba(63, 94, 251, 0.8)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
  mb: 2,
  "@keyframes fadeInOut": {
    "0%": { opacity: 1 },
    "50%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  boxShadow: "0px 0px 15px rgba(0,0,0,0.5)", // Glowing effect
  cursor: "pointer",
};

export const stopMicIconStyles = {
  color: "#1976d2",
  padding: "4px",
  borderRadius: "50%",
  backgroundColor: "rgba(25, 118, 210, 0.1)",
  boxShadow: "0px 0px 5px rgba(25, 118, 210, 0.5)",
  cursor: "pointer",
};

export const micIconStyles = (isRecording: boolean) => {
  return {
    mb: 2,
    color: "#1976d2", // Primary color
    transform: "scale(5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    width: "fit-content",
    animation: isRecording ? "pulsate 2s infinite" : "none",
    backgroundColor: "rgba(25, 118, 210, 0.1)", // Light blue background
    boxShadow: "0px 0px 5px rgba(25, 118, 210, 0.5)",
    "&:hover": {
      backgroundColor: "rgba(25, 118, 210, 0.2)",
    },
    "@keyframes pulsate": {
      "0%": {
        transform: "scale(5)",
        color: "#1976d2",
      },
      "50%": {
        transform: "scale(5.1)",
        color: "red",
      },
      "100%": {
        transform: "scale(5)",
        color: "#1976d2",
      },
    },
  };
};

// Adjust tab styles to match Otter theme colors
export const commonTabStyles = {
  border: "1px solid #1976d2", // Light blue border
  borderRadius: "24px",
  padding: "6px 16px",
  margin: "0 8px",
  maxWidth: isMobile ? "30%" : "auto",
  minWidth: isMobile ? "50px" : "275px",
  color: "#1976d2", // Primary color
};

export const selectedTabStyles = {
  "&.Mui-selected": {
    borderColor: "#1976d2",
    backgroundColor: "#1976d2",
    color: "#FFFFFF", // White for selected text
  },
  "&:hover": {
    borderColor: "#1976d2",
    backgroundColor: "rgba(25, 118, 210, 0.1)", // Lighter blue on hover
  },
};

export const darkCountdownTypographyStyles = {
  color: darkTheme.palette.text.primary,
  fontWeight: "bold",
  animation: "pop 1s ease-out infinite", // Add the pop animation for each second
  "@keyframes pop": {
    "0%": {
      opacity: 1,
      transform: "scale(1)",
    },
    "50%": {
      opacity: 0,
      transform: "scale(1)",
    },
    "100%": {
      opacity: 0,
    },
  },
};

export const darkCountdownBoxStyles = {
  width: 170,
  height: 170,
  borderRadius: "50%",
  backgroundColor: "rgba(63, 94, 251, 0.8)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 auto",
  mb: 2,
  "@keyframes fadeInOut": {
    "0%": { opacity: 1 },
    "50%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
  boxShadow: "0px 0px 15px rgba(0,0,0,0.5)", // Glowing effect
  cursor: "pointer",
};

export const darkStopMicIconStyles = {
  color: "#ffffff",
  padding: "4px",
  borderRadius: "50%",
  backgroundColor: "rgba(255, 255, 255, 0.1)",
  boxShadow: "0px 0px 5px rgba(255, 255, 255, 0.5)",
  cursor: "pointer",
};

export const darkMicIconStyles = (isRecording: boolean) => {
  return {
    mb: 2,
    color: "#FFFFFF",
    transform: "scale(5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
    width: "fit-content",
    animation: isRecording ? "pulsate 2s infinite" : "none",
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    boxShadow: "0px 0px 5px rgba(255, 255, 255, 0.5)",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.1)",
    },
    "@keyframes pulsate": {
      "0%": {
        transform: "scale(5)",
        color: "#FFFFFF",
      },
      "50%": {
        transform: "scale(5.1)",
        color: "red",
      },
      "100%": {
        transform: "scale(5)",
        color: "#FFFFFF",
      },
    },
  };
};

// Adjust tab styles to match Otter theme colors
export const darkCommonTabStyles = {
  border: `1px solid ${darkTheme.palette.primary.main}`, // Light blue border
  borderRadius: "24px",
  padding: "6px 16px",
  margin: "0 8px",
  maxWidth: isMobile ? "30%" : "auto",
  minWidth: isMobile ? "50px" : "275px",
  color: "#ffffff", // Primary color
};

export const darkSelectedTabStyles = {
  "&.Mui-selected": {
    borderColor: darkTheme.palette.primary.main,
    backgroundColor: darkTheme.palette.primary.main,
    color: "#ffffff", // White for selected text
  },
  "&:hover": {
    borderColor: darkTheme.palette.primary.main,
    backgroundColor: "#b28434", // Lighter blue on hover
  },
};

// export const darkTabStyles = { ...darkCommonTabStyles, ...darkSelectedTabStyles };
// export const tabStyles = { ...commonTabStyles, ...selectedTabStyles };
export const tabStyles = { ...darkCommonTabStyles, ...darkSelectedTabStyles };
